<template>
  <div class="page">
      <SetParam
        :pushStatus="pushStatus"
        :relationId="relationId"
        :relationName="relationName"
      />
  </div>
</template>

<script>
import {SetParam} from '@/components'
export default {
  name: 'SetParamPage',
  components: {SetParam},
  data() {
    return {
      pushStatus: null,
      relationId: null,
      relationName: null,
    }
  },
  created() {
    this.pushStatus = this.$route.query.pushStatus
    this.relationId = this.$route.query.id
    this.relationName = this.$route.query.relationName
  }
}
</script>

<style lang="scss" scoped>
  .page-main {
    padding: 0;
  }
</style>